import $ from 'jquery';
import 'what-input';
import Quill from 'quill';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
const KeenSlider = require('../../../node_modules/keen-slider/keen-slider');
const Checker = require('password-validator');
//const selecter = require('./lib/multiselect-dropdown');


$(document).foundation();

$(window).ready(() => {
    Quill.import('delta')
    $('.quill-editor').each(function (i, el) {
        var el = $(this), id = 'quilleditor-' + i, val = el.val(), editor_height = 200;
        var div = $('<div/>').attr('id', id).css('height', editor_height + 'px').html(val);
        el.addClass('hide');
        el.parent().append(div);

        var quill = new Quill('#' + id, {
            modules: {
                toolbar: true,
            },
            placeholder: 'Please write your content here.',
            theme: 'snow'
        });
        quill.on('text-change', function () {
            el.html(quill.getSemanticHTML());
        });
    });

})

function navigation(slider) {
    let wrapper, dots, arrowLeft, arrowRight

    function markup(remove) {
        wrapperMarkup(remove)
        dotMarkup(remove)
        arrowMarkup(remove)
    }

    function removeElement(element) {
        element.parentNode.removeChild(element)
    }

    function createDiv(className) {
        let div = document.createElement("div")
        let classNames = className.split(" ")
        classNames.forEach((name) => div.classList.add(name))
        return div
    }

    function arrowMarkup(remove) {
        if (remove) {
            removeElement(arrowLeft)
            removeElement(arrowRight)
            return
        }
        arrowLeft = createDiv("arrow arrow-left")
        arrowLeft.addEventListener("click", () => slider.prev())
        arrowRight = createDiv("arrow arrow-right")
        arrowRight.addEventListener("click", () => slider.next())

        wrapper.appendChild(arrowLeft)
        wrapper.appendChild(arrowRight)
    }

    function wrapperMarkup(remove) {
        if (remove) {
            let parent = wrapper.parentNode
            while (wrapper.firstChild)
                parent.insertBefore(wrapper.firstChild, wrapper)
            removeElement(wrapper)
            return
        }
        wrapper = createDiv("navigation-wrapper")
        slider.container.parentNode.appendChild(wrapper)
        wrapper.appendChild(slider.container)
    }

    function dotMarkup(remove) {
        if (remove) {
            removeElement(dots)
            return
        }
        dots = createDiv("dots")
        slider.track.details.slides.forEach((_e, idx) => {
            let dot = createDiv("dot")
            dot.addEventListener("click", () => slider.moveToIdx(idx))
            dots.appendChild(dot)
        })

        wrapper.appendChild(dots)
    }

    function updateClasses() {
        let slide = slider.track.details.rel
        slide === 0
            ? arrowLeft.classList.add("arrow-disabled")
            : arrowLeft.classList.remove("arrow-disabled")
        slide === slider.track.details.slides.length - 1
            ? arrowRight.classList.add("arrow-disabled")
            : arrowRight.classList.remove("arrow-disabled")
        Array.from(dots.children).forEach(function (dot, idx) {
            idx === slide
                ? dot.classList.add("dot--active")
                : dot.classList.remove("dot--active")
        })
    }

    slider.on("created", () => {
        markup()
        updateClasses()
    })
    slider.on("optionsChanged", () => {
        console.log(2)
        markup(true)
        markup()
        updateClasses()
    })
    slider.on("slideChanged", () => {
        updateClasses()
    })
    slider.on("destroyed", () => {
        markup(true)
    })
}

function autoplay(run) {
    setInterval(function () {
        if (run) {
            partnerSlider.next();
            techSlider.next();
        }
    }, 9000);
}

$(document).ready(() => {
    if ($('#partner-slider')) {
        console.log($('#partner-slider'))
        part();
    }
    if ($('#tech-slider')) {
        tech();
    }
})
let partnerSlider;
let techSlider;

function part() {
    partnerSlider = new KeenSlider(
        '#partner-slider',
        {
            loop: true,
            mode: 'free',
            duration: 800,
            breakpoints: {
                "(max-width: 640px) and (min-width:300px)": {
                    slides: {perView: 1, spacing: 5},
                },
                "(max-width: 1024px) and (min-width:641px)": {
                    slides: {perView: 2, spacing: 10},
                },
            },
            slides: {
                perView: 3
            }
        }, [navigation]
    )
    autoplay(true)
}

function tech() {
    techSlider = new KeenSlider(
        '#tech-slider',
        {
            loop: true,
            mode: 'free',
            duration: 800,
            breakpoints: {
                "(max-width: 640px) and (min-width:300px)": {
                    slides: {perView: 1, spacing: 5},
                },
                "(max-width: 1024px) and (min-width:641px)": {
                    slides: {perView: 2, spacing: 10},
                },
            },
            slides: {
                perView: 3
            }
        }, [navigation]
    )
    autoplay(true)
}


$(window).ready(function () {
    let check1 = false;
    let check2 = false;
    let passValid = false;

    $('.pass').css('display', 'none');
    $('#policy_idni').on('click', () => {
        check1 = !check1;
        if (check1 && check2) {
            $('.pass').css('display', 'block');
        } else {
            $('.pass').css('display', 'none');
        }
    })
    $('#policy_bill').on('click', () => {
        check2 = !check2;
        if (check1 && check2) {
            $('.pass').css('display', 'block');
        } else {
            $('.pass').css('display', 'none');
        }
    })

    $('#password').on('keyup', () => {
        let pass = $('#password').val()
        let schema = new Checker();
        schema
            .is().min(8, 'Your password should have a minimum length of 8 characters')
            .is().max(100, 'Your password cannot be more than 100 characters')
            .has().uppercase(1, 'Your password must include at least 1 uppercase letter')
            .has().lowercase(1, 'Your password must include at least 1 lowercase letter')
            .has().digits(1, 'Your password must include at least 1 number')
            .has().symbols(1, 'Your password must include at least 1 special characters')
            .has().not().spaces(null, 'Password cannot contain any spaces')
            .is().not().oneOf([
            'Passw0rd',
            'Password123',
            '123456',
            'password',
            '123456789',
            '12345678',
            '12345',
            '1234567',
            'admin',
            '123123',
            'qwerty',
            'abc123',
            'letmein',
            'monkey',
            '111111',
            'password1',
            'qwerty123',
            'dragon',
            '1234',
            'baseball',
            'iloveyou',
            'trustno1',
            'sunshine',
            'princess',
            'football',
            'welcome',
            'shadow',
            'superman',
            'michael',
            'ninja',
            'mustang',
            'jessica',
            'charlie',
            'ashley',
            'bailey',
            'passw0rd',
            'master',
            'love',
            'hello',
            'freedom',
            'whatever',
            'nicole',
            'jordan',
            'cameron',
            'secret',
            'summer',
            '1q2w3e4r',
            'zxcvbnm',
            'starwars',
            'computer',
            'taylor',
            'startrek'
        ], 'Your password cannot be a common password');


        $('#passwordErrorList').html(
            schema.validate(pass, {details: true}).map(x => {
                return '<li>' + x.message + '</li>'
            }));

        passValid = !schema.validate(pass, {list: true}).length;
    })
    $('#confirmPassword').css('display', 'none')
    $('#confirm_password').on('keyup', () => {
        let pass = $('#password').val()
        if (pass === $('#confirm_password').val()) {
            $('#confirmPassword').css('display', 'none');
        } else {
            $('#confirmPassword').css('display', 'block')
        }
    })

    $('#submit').on('click', (e) => {

        if (check1 && check2) {
            let pass = $('#password').val()
            let passSame = false;
            passSame = pass === $('#confirm_password').val();
            if (!passValid || !passSame) {
                e.preventDefault()
            }
        }
    })
})


// SIDEBAR MENU - BURGER MENU
const menu = document.querySelector(".sidenav");
const menuItems = document.querySelectorAll(".menuItem");
const hamburger = document.querySelector(".hamburger");
const closeIcon = document.querySelector(".closeIcon");
const menuIcon = document.querySelector(".menuIcon");

function toggleMenu() {
    if (menu.classList.contains("showMenu")) {
        menu.classList.remove("showMenu");
        closeIcon.style.display = "none";
        menuIcon.style.display = "block";
    } else {
        menu.classList.add("showMenu");
        closeIcon.style.display = "block";
        menuIcon.style.display = "none";
    }
}

hamburger.addEventListener("click", toggleMenu);

menuItems.forEach(
    function (menuItem) {
        menuItem.addEventListener("click", toggleMenu);
    }
)


// COSI CREAT AND JOIN FUNCTIONS

$(".show-button").click(function () {
    $("#join-cosi-card").hide()
    $(".content-reveal").show()
    //$(".content-reveal").stop().slideUp(300);
    //$(this).next(".content-reveal").stop().slideToggle(300);
});

$(".close-join-panel").click(function () {
    $("#join-cosi-card").show()
    $(".content-reveal").hide()
});


$(".create-cosi").click(function () {
    $("#create-cosi-card").hide()
    $(".cosi-form").show()
    //$(".cosi-form").stop().slideUp(300);
    //$(this).next(".cosi-form").stop().slideToggle(300);
});

$(".close-btn").click(function () {
    $("#create-cosi-card").show()
    $(".cosi-form").hide()
});


$(".show-search-bar").click(function () {
    $(".search-panel").show()
})

